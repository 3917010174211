import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// let tl = gsap.timeline({
//   scrollTrigger: {
//     trigger: ".chart",
//     start: "top 50%", // 捲動到元素的 50% 時觸發
//     onEnter: function () {
//       if (tl.progress() === 0) {
//         tl.play(0);
//       }
//     }
//   }
// });
// $('.chart__value').text(0);
// $('.chart__value').hide();

// tl.call(function () {
//   $('.chart__value').text(0);
//   $('.chart__value').hide();

//   $('.tab-pane.show .chart__unit.first .chart__value').each(function () {
//     let $this = $(this);
//     $this.show();
//     let isInit = Number.isInteger($this.data("to"));
//     $({
//       Counter: 0
//     }).animate({
//       Counter: $this.data("to")
//     }, {
//       duration: 1000,
//       step: function () {
//         if (isInit) {
//           $this.text(Math.ceil(this.Counter));
//         } else {
//           $this.text(this.Counter.toFixed(2));
//         }
//       },
//       complete: function () {
//         $this.text($this.data("to"));
//       }
//     });
//   });
// });
// tl.fromTo(".tab-pane.show .chart__unit.first .chart__line", {
//   width: "0%"
// }, {
//   duration: 1,
//   width: function (index, target) {
//     return target.getAttribute("data-width");
//   }
// });

// tl.call(function () {
//   $('.tab-pane.show .chart__unit.last .chart__value').each(function () {
//     let $this = $(this);
//     $this.show();
//     let isInit = Number.isInteger($this.data("to"));
//     $({
//       Counter: 0
//     }).animate({
//       Counter: $this.data("to")
//     }, {
//       duration: 1000,
//       step: function () {
//         if (isInit) {
//           $this.text(Math.ceil(this.Counter));
//         } else {
//           $this.text(this.Counter.toFixed(2));
//         }
//       },
//       complete: function () {
//         $this.text($this.data("to"));
//       }
//     });
//   });
// });

// tl.fromTo(".tab-pane.show .chart__unit.last .chart__line", {
//   width: "0%"
// }, {
//   duration: 1,
//   width: function (index, target) {
//     return target.getAttribute("data-width");
//   }
// });
// tl.pause(0);

let wifiTl = gsap.timeline();
wifiTl.fromTo(".tab-pane.show .chart__line.first", {
  width: "0%"
}, {
  duration: 3,
  width: "100%"
});
wifiTl.fromTo(".tab-pane.show .chart__line.last", {
  width: "0%"
}, {
  duration: 3,
  width: "100%"
});
wifiTl.pause(0);

const tls = [];
document.querySelectorAll(".chart").forEach((el) => {
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: "top 50%", // 捲動到元素的 50% 時觸發
      onEnter: function () {
        if (tl.progress() === 0) {
          tl.play(0);
        }
      }
    }
  });
  $(el).find('.chart__value').text(0);
  $(el).find('.chart__value').hide();

  tl.call(function () {
    $(el).find('.chart__value').text(0);
    $(el).find('.chart__value').hide();
  
    $(el).find('.chart__unit.first .chart__value').each(function () {
      let $this = $(this);
      $this.show();
      let isInit = Number.isInteger($this.data("to"));
      $({
        Counter: 0
      }).animate({
        Counter: $this.data("to")
      }, {
        duration: 1000,
        step: function () {
          if (isInit) {
            $this.text(Math.ceil(this.Counter));
          } else {
            $this.text(this.Counter.toFixed(2));
          }
        },
        complete: function () {
          $this.text($this.data("to"));
        }
      });
    });
  });
  tl.fromTo($(el).find('.chart__unit.first .chart__line'), {
    width: "0%"
  }, {
    duration: 1,
    width: function (index, target) {
      return target.getAttribute("data-width");
    }
  });
  
  tl.call(function () {
    $(el).find('.chart__unit.last .chart__value').each(function () {
      let $this = $(this);
      $this.show();
      let isInit = Number.isInteger($this.data("to"));
      $({
        Counter: 0
      }).animate({
        Counter: $this.data("to")
      }, {
        duration: 1000,
        step: function () {
          if (isInit) {
            $this.text(Math.ceil(this.Counter));
          } else {
            $this.text(this.Counter.toFixed(2));
          }
        },
        complete: function () {
          $this.text($this.data("to"));
        }
      });
    });
  });
  
  tl.fromTo($(el).find(".chart__unit.last .chart__line"), {
    width: "0%"
  }, {
    duration: 1,
    width: function (index, target) {
      return target.getAttribute("data-width");
    }
  });
  tl.from($(el).find('.chart__percent'), {
    autoAlpha: 0,
    duration: .5
  });
  tl.pause(0);

  tls.push(tl);
});

$('.benchmark__tab a').on('shown.bs.tab', function (e) {
  if (e.target.getAttribute("href") == "#bench_wifi") {
    wifiTl.restart();
  } else {
    tls.forEach((tl) => {
      tl.restart();
    });
  }
});