function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  let results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

$(function(){
  let currentProductName;
  
  let parameterProduct = getUrlParameter("product");
  if(parameterProduct) {
    document.getElementById('productName').value = parameterProduct;
    
    $('#content').empty();
    getDataByID(parameterProduct);
  }
  
  $("#productName").change(function() {
    doneData = [];
    currentProductName = $(this).children("option:selected").val()

    $("#content").empty();
    getDataByID(currentProductName);

    let url = [location.protocol, '//', location.host, location.pathname].join('');

    window.history.pushState("", "", url+"?product=" + currentProductName);
  });
});

let loadedLength, dataLength;
function getDataByID(type) {
  let model, pdhashedid;
  switch (type) {
    case "tinker-board-3":
      model = "Tinker%20Board%203";
      pdhashedid = "";
      break;
    case "tinker-board-3n":
      model = "Tinker%20Board%203N";
      pdhashedid = "";
      break;
    case "tinker-board-s-r2":
      model = "Tinker%20Board%20S%20R2.0";
      pdhashedid = "";
      break;
    case "tinker-board-r2":
      model = "Tinker%20Board%20R2.0";
      pdhashedid = "";
      break;
    case "tinker-board-2s":
      model = "Tinker%20Board%202S";
      pdhashedid = "fc7c150sl8bhdbd3";
      break;
    case "tinker-board-2":
      model = "Tinker%20Board%202";
      pdhashedid = "smr38stvt2fxdd6x";
      break;
    case "tinker-board-s":
      model = "Tinker%20Board%20S";
      pdhashedid = "xOd5XdS4L5c6tt1O";
      break;
    case "tinker-board":
      model = "Tinker%20Board";
      pdhashedid = "UUSx6FPuqP3uZrhv";
      break;
    case "tinker-edge-t":
      model = "Tinker%20Edge%20T";
      pdhashedid = "e8y6rivrrnvl0d1y";
      break;
    case "tinker-edge-r":
      model = "Tinker%20Edge%20R";
      pdhashedid = "";
      break;
    default:
      return;
  }

  fetch('https://www.asus.com/support/api/product.asmx/GetPDOS?pdhashedid='+pdhashedid+'&website=global&cpu=&model='+model+'&site=tinkerboard')
    .then(response => response.json())
    .then(data => {
      loadedLength = 0;
      dataLength = data.Result.Obj.length;
      for (let index = 0; index < data.Result.Obj.length; index++) {
        ajaxCallJsonp("https://www.asus.com/support/api/product.asmx/GetPDDrivers?cpu=&osid="+data.Result.Obj[index].Id+"&website=global&pdhashedid="+pdhashedid+"&model="+model+"&site=tinkerboard");
      }
    })
}

let doneData = [];
function ajaxCallJsonp(target) {
  fetch(target)
    .then(response => response.json())
    .then(data => {
      loadedLength++;

      let dataObj = data.Result.Obj['0'].Files;
      for(let item in dataObj) {
        doneData.push(dataObj[item]);
      }

      if (loadedLength == dataLength) {
        //日期 降序 排列
        doneData.sort(function(a, b) {
          return new Date(b.ReleaseDate) - new Date(a.ReleaseDate);
        });

        setData(doneData)
        doneData = [];
      }
    })
}

function setData(data) {
  $.each(data, function (index) {
    duplicateAndSetData(index, this);
  });
}

function duplicateAndSetData(index, data) {
  let hashID = data.ReleaseDate.split('/').join('');

  let $new = $('#original').clone().appendTo( "#content" ).removeAttr("hidden").removeAttr("id");

  $new.find(".title").html(data.Title).attr("href", "#download"+hashID);
  $new.find(".date").html(data.ReleaseDate);
  $new.find(".size").html(data.FileSize);
  $new.find(".btn").attr("href", data.DownloadUrl.Global).attr('onclick', "gtag('event', 'DL Click', {'event_category': 'Click', 'event_label':'"+ data.Title +"'})");
  $new.find(".state").attr("href", "#download"+hashID);

  $new.find(".collapse").attr("id", "download"+hashID);
  $new.find(".content").html(data.Description);
}